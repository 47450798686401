/**
 * @param {string} type
 * @param {string} text
 */
export const createAlert = (type, text) => {
    const alert = document.createElement('div');
    alert.classList.add('alert', `alert--${type}`);
    alert.innerText = text;
    return alert;
};

/**
 * @param {HTMLElement} el
 */
export const removeAlerts = (el) => {
    for (const alertEl of el.querySelectorAll('.alert')) {
        alertEl.remove();
    }
};
