export const addEventListener = (selector, eventName, handler) => {
    document.addEventListener(eventName, (event) => {
        if (event.target.closest(selector)) {
            handler.call(event.target, event);
        }
    });
};

/**
 * Binds an event handler for clicking on a '.type .action-btn'.
 * (Helper to prevent code duplication for post / comment buttons.
 *
 * @param {string}      type     Class of the content element (e.g. for '.comment' just give 'comment')
 * @param {string}      action   Class for the trigger button element (e.g. for '.comment .vote-btn' just give 'vote')
 * @param {function}    onClick  Callback when the button is clicked.
 *                               The onClick callback receives:
 *                                   $btn     jQuery button element that was clicked
 *                                   $content jQuery element for the .post/.comment etc.
 *                                   id       data-id attribute from the element
 * @param {boolean|undefined} showLoading  Show a loading spinner?
 * @param {string|undefined} actionText Description of the action, used in the login modal.
 * @param {boolean|undefined} preventDefault
 */
/**
 * Binds an event handler for clicking on a button like '.post .vote-btn'.
 *
 * @param {string} contentType Class of the content element (e.g. for '.comment' just give 'comment')
 * @param {string} actionType Class for the trigger button element (e.g. for '.comment .vote-btn' just give 'vote').
 * @param {function} handler Callback when the button is clicked. The onClick callback receives:
 *                              btnEl HTMLElement for the button that was clicked.
 *                              contentEl HTMLElement for the content that the button that was clicked in.
 *                              id Number from the data-id attribute from the content element.
 *                              event
 * @param {boolean} showLoading
 */
export const onContentBtn = (contentType, actionType, handler, showLoading = true) => {
    const btnSelector = `.${contentType}__${actionType}-btn`;

    document.addEventListener('click', async (event) => {
        const btnEl = event.target.closest(btnSelector);
        if (btnEl) {
            event.preventDefault();

            const contentEl = btnEl.closest(`.${contentType}`);
            const iconEl = btnEl.querySelector('i');
            const id = contentEl.getAttribute(`data-${contentType}-id`);

            if (showLoading) {
                setButtonLoading(btnEl);
            }

            try {
                await handler(btnEl, contentEl, id, event, iconEl);
            } catch (e) {

            } finally {
                if (showLoading) {
                    clearButtonLoading(btnEl);
                }
            }
        }
    });
};

/**
 * @param {HTMLButtonElement} btnEl
 */
export const setButtonLoading = (btnEl) => {
    btnEl.disabled = true;
    const iconEl = btnEl.querySelector('i');
    if (iconEl) {
        iconEl.classList.add('fa-spinner', 'fa-spin');
    }
};

/**
 * @param {HTMLButtonElement} btnEl
 */
export const clearButtonLoading = (btnEl) => {
    btnEl.disabled = false;
    const iconEl = btnEl.querySelector('i');
    if (iconEl) {
        iconEl.classList.remove('fa-spinner', 'fa-spin');
    }
};
